import React, { useState } from "react"
import tw from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

export function Carousel({ images, ...rest }) {
  const [[page, direction], setPage] = useState([0, 0])
  const imageIndex = wrap(0, images.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  const CarouselWrapper = styled.div`
    ${tw`overflow-hidden block relative`};
    ${p => p.$Small && tw`h-64`}
  `

  const variants = {
    enter: direction => {
      return {
        x: direction > 0 ? 40 : -40,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: direction => {
      return {
        zIndex: 0,
        x: direction < 0 ? 40 : -40,
        opacity: 0,
      }
    },
  }

  return (
    <CarouselWrapper {...rest} data-test-id={"carousel"}>
      <AnimatePresence
        exitBeforeEnter={true}
        initial={false}
        custom={direction}
      >
        <motion.img
          key={page}
          src={images[imageIndex].asset.localFile?.childImageSharp?.fullsize?.src || images[imageIndex].asset.fluid.src}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          tw={"w-full"}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.1 },
          }}
        />
      </AnimatePresence>
      <motion.button
        whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
        whileTap={{ scale: 0.9 }}
        onClick={() => paginate(1)}
        tw={"bg-white z-30 absolute p-2"}
        css={`
          right: 0px;
          top: 50%;
        `}
      >
        <FontAwesomeIcon icon={faArrowRight} size={"2x"} />
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
        whileTap={{ scale: 0.9 }}
        onClick={() => paginate(-1)}
        tw={"bg-white z-30 absolute p-2"}
        css={`
          left: 0;
          top: 50%;
        `}
      >
        <FontAwesomeIcon icon={faArrowLeft} size={"2x"} />
      </motion.button>
    </CarouselWrapper>
  )
}

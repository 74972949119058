import React from "react"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

let FileIcons = new Map([["application/pdf", faFilePdf]])

let AttachmentLink = tw.a`flex gap-4 items-center text-blue-800 text-lg mt-2`

export function AttachmentsBlock({ url, originalFilename, mimeType }) {
  return (
    <AttachmentLink href={url}>
      <FontAwesomeIcon icon={FileIcons.get(mimeType)} size="xl" color={""} />
      <p>{originalFilename}</p>
    </AttachmentLink>
  )
}

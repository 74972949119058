import React from "react"
import tw, { styled } from "twin.macro"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HeroWrapper = styled.section`
  ${tw`w-full  bg-cover block z-10 py-32 relative items-center`};
  background-image: url(${p => p.background});
  position: relative;
  background-position: center;
  z-index: -1;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
  }
`

const Title = tw.h1`
  text-primary text-5xl font-bold font-sans pb-8 leading-tight text-center z-10 relative
`

const HeroCopy = styled.p`
  ${tw`text-primary font-sans text-center text-xl pb-8`}
`

const HeaderCopyArea = tw.div`
relative z-10 w-full

`

const Container = styled.div`
  ${tw`container h-full flex items-center min-h-full`};
  z-index: 5;
`

const DownArrow = styled(FontAwesomeIcon)`
  ${tw`text-primary text-center mx-auto block mt-12`};
`

export let Hero = ({ background, headline, tagline, salesPage }) => {
  const { src } = background?.asset?.localFile?.childImageSharp?.fluid
  return (
    <HeroWrapper background={src}>
      <Container>
        <HeaderCopyArea>
          {headline && <Title>{headline}</Title>}
          {tagline && <HeroCopy>{tagline}</HeroCopy>}
          {salesPage && (
            <HeroCopy salesPage={salesPage}>
              RMS is inviting offers on surplus rolling stock made available for
              disposal. Please see information below as well as how to get in
              touch
            </HeroCopy>
          )}
          <DownArrow icon={faChevronDown} size={"2x"} />
        </HeaderCopyArea>
      </Container>
    </HeroWrapper>
  )
}
